import axios from "axios";

export const loginWithMobile = async (mobile, amount, transaction_id, latitude, longitude) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}api/vouchers/send-otp`,
        {
          mobile,
          amount,
          transaction_id,
          latitude,
          longitude // Include latitude and longitude in the request payload
        },
        {
          headers: {
            "Accept": "*/*"
          }
        }
      );
      return response;
    } catch (err) {
      return err.message;
    }
  };
  