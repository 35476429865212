import { useEffect, useState, useContext } from "react";
import { Alert } from "react-bootstrap";
import { loginWithOtp } from "../service/login";
import { useNavigate, useParams } from "react-router-dom";
import { loginWithMobile } from "../service/loginWithMobile";
import { LoaderContext } from "../context/LoaderContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../context/ThemeProvider";
import { loginWithCoupon } from "../service/loginWithCoupon";
import { toast } from "react-toastify";
import Footer from "../layout/Footer";
import axios from "axios";

export default function Login() {
  const { theme, setTheme } = useContext(ThemeContext);
  const params = useParams();

  const [otp, setOtp] = useState();
  // const [countdown, setCountDown] = useState("");
  const [submitBtn, setSubmitBtn] = useState(true);
  const [alert, setAlert] = useState({ state: false, variant: "", text: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  // const [newuser, setNewUser] = useState(false)
  const [isOtpSent, SetIsOtpSent] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileAlert, setMobileAlert] = useState({ state: false });
  const [errorModal, setErrorModal] = useState(false);
  const [couponUser, setCouponUser] = useState(null);
  const [isWillingWithCoupon, setIsWillingWithCoupon] = useState(true);

  const navigate = useNavigate();

  const { setLoading } = useContext(LoaderContext);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}api/vouchers/vh/${params.hash}`;

    axios
      .get(apiUrl)
      .then((response) => {
        console.log("API Response:", response.data);
        setLoading(true);
        if (response.status === 200 && response.data.success === true) {
          setLoading(false);
          sessionStorage.setItem("apiResponse", JSON.stringify(response.data));
          const storedApiResponse = sessionStorage.getItem("apiResponse");
          if (storedApiResponse) {
            const apiResponse = JSON.parse(storedApiResponse);
            const phoneNumber = apiResponse?.userData?.mobile;
            setMobile(phoneNumber);
          }
        } else {
          navigate("/thankyou");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        navigate("/thankyou");
      });
  }, [params.hash]);

  useEffect(() => {
    if (seconds > 0 || minutes > 0) {
      const timer = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(timer);
          } else {
            setMinutes(prevMinutes => prevMinutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds(prevSeconds => prevSeconds - 1);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [seconds, minutes, timerActive]);

  const StoredapiResponse = sessionStorage.getItem("apiResponse");
  const apiResponse = JSON.parse(StoredapiResponse);
  const amount = apiResponse?.userData?.amount;
  const transaction_id = apiResponse?.userData?.almond_transaction_id;

  const handleChange = async (e) => {
    if (e.target.name == "otp") {
      document.getElementById("otp-input").value = e.target.value.replaceAll(
        /[^0-9]/g,
        ""
      );
    }

    setOtp({ ...otp, [e.target.name]: e.target.value });
  };

  const getUserGeolocationWithRetry = async (
    defaultLatitude,
    defaultLongitude
  ) => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            reject(error);
          }
        );
      });

      const { latitude, longitude } = position;

      // Log latitude and longitude to the console
      console.log("Latitude:", latitude);
      console.log("Longitude:", longitude);

      return { latitude, longitude };
    } catch (error) {
      // If permission is denied, use default values
      if (error.code === error.PERMISSION_DENIED) {
        console.error(
          "Permission denied for geolocation. Using default values."
        );
        return { latitude: defaultLatitude, longitude: defaultLongitude };
      } else {
        throw error; // Propagate other errors
      }
    }
  };

  useEffect(() => {
    console.log(otp);
  }, [otp]);

  const handleAlert = (variant, text, duration) => {
    setAlertData({ variant, text });
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      setAlertData({});
    }, duration);
  };

  const handleMobileInput = async (e) => {
    if (validateMobile(e.target.value)) {
      setMobile(e.target.value);
      setMobileAlert({ state: false, text: "" });
    } else {
      const input = e.target;
      input.value = input.value.replace(/[^0-9]/g, "");
    }
  };

  const validateMobile = (value) => {
    value.replace(/\D/g, "");
    if (value[0] < 5) {
      setMobileAlert({
        state: true,
        text: "Mobile number must not start with 0,1,2,3,4 and 5.",
      });
      return false;
    } else if (/^\d{10}$/.test(value)) {
      return true;
    } else {
      setMobileAlert({
        state: true,
        text: "Mobile number must contain 10 digits !",
      });
      return false;
    }
  };

  //   useEffect(() => {
  //     if (mobile?.length === 10 && parseInt(mobile) >= 5000000000) {
  //       setMobileAlert({ state: false });
  //     } else if (mobile?.length === 10) {
  //       setMobileAlert({
  //         state: true,
  //         text: "Mobile number must be greater than or equal to 5000000000.",
  //       });
  //     } else {
  //       setMobileAlert({
  //         state: true,
  //         text: "Mobile number must contain 10 digits.",
  //       });
  //     }
  //   }, [mobile]);

  const handleSubmit = async () => {
    if (submitBtn) {
      setLoading(true);
      try {
        const { latitude, longitude } = await getUserGeolocationWithRetry(
          28.411776,
          77.0449214
        );

        const response = await loginWithOtp(
          parseInt(`${otp?.otp}`),
          mobile,
          transaction_id,
          latitude,
          longitude
        );
        const { success, accessToken } = response;
        if (success) {
          setLoading(false);
          handleAlert("success", "OTP Submitted Successfully", 3000);
          navigate("/");
        } else {
          setLoading(false);
          handleAlert("danger", "OTP did not match", 3000);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleOtpSent = async () => {
    if (!mobileAlert.state) {
      try {
        setLoading(true);

        const { latitude, longitude } = await getUserGeolocationWithRetry(
          28.411776,
          77.0449214
        );

        const response = await loginWithMobile(
          mobile,
          amount,
          transaction_id,
          latitude,
          longitude
        );

        setLoading(false);

        if (response.data.success === true) {
          SetIsOtpSent(true);
        } else {
          toast.error("Invalid mobile number");
        }
      } catch (err) {
        if (err.message === "Permission denied for geolocation") {
          toast.error("Please enable location access to proceed.");
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        } else {
          toast.error("Please provide a mobile number");
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAlert({ state: false, variant: "", text: "" });
    }, 5000);
  }, [alert]);

  function clickEvent(first, last) {
    if (first?.value?.length) {
      document.getElementById(last).focus();
    }
  }

  // var countDownDate = new Date().getTime() + (90 * 1000);

  // var x = setInterval(function () {

  //     var now = new Date().getTime();

  //     var distance = countDownDate - now;
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (minutes < 10 && seconds < 10) {
  //         setCountDown(`0${minutes} : 0${seconds}`);
  //     } else if (minutes < 10) {
  //         setCountDown(`0${minutes} : ${seconds}`);
  //     } else if (seconds < 10) {
  //         setCountDown(`${minutes} : 0${seconds}`);
  //     } else {
  //         setCountDown(`${minutes} : ${seconds}`);
  //     }
  // })

  // const handleInputChange = async (e) => {
  //     setCouponUser({ ...couponUser, [e.target.name]: e.target.value });
  //     console.log(e.target.name, e.target.value);
  // }

  // const handleLoginWithCoupon = async (e) => {
  //     if (couponUser && (Object.values(couponUser).length > 1) && isWillingWithCoupon) {
  //         setLoading(true);
  //         const response = await loginWithCoupon(couponUser);
  //         setLoading(false);
  //         console.log(response);
  //         if (response?.data?.success) {
  //             navigate("/")
  //             localStorage.setItem("token", response?.data?.accessToken);
  //         } else if (response.status !== 200) {
  //             console.log("alert")
  //             toast.error("Invalid Details");
  //         }
  //     } else {
  //         if (isWillingWithCoupon)
  //             toast.error("Please fill all the fields");
  //     }
  // }

  useEffect(() => {
    console.log(couponUser);
  }, [couponUser]);

  const resendOTP = async () => {

    const { latitude, longitude } = await getUserGeolocationWithRetry(
      28.411776,
      77.0449214
    );

    const response = await loginWithMobile(
      mobile,
      amount,
      transaction_id,
      latitude,
      longitude
    );

    setLoading(false);

    if (response.data.success === true) {
      SetIsOtpSent(true);
    } else {
      toast.error("Invalid mobile number");
    }

    setSeconds(0); // Reset seconds to maximum value
    setMinutes(1);
    setTimerActive(true);
  };

  return (
    <>
      <div className="container">
        {showAlert && (
          <Alert variant={alertData.variant || ""} dismissible>
            {alertData.text || ""}
          </Alert>
        )}
        <img
          className="login-logo"
          src={theme ? "/images/pepsi.png" : "/images/pepsi.png"}
          alt=""
        />
        {isOtpSent ? (
          <div className="otp-container">
            {/* <h1>Verify OTP</h1> */}
            <h3>Please enter OTP sent on your mobile number </h3>
            <input
              className="phone-input"
              type="text"
              maxLength={4}
              pattern="\d*"
              onChange={handleChange}
              id="otp-input"
              name="otp"
              style={{ margin: "30px auto" }}
              placeholder="Enter OTP"
            />

            <div className="countdown-text" style={{display: 'flex', justifyContent: 'space-between'}}>
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p>Didn't recieve OTP?</p>
              )}

              <p onClick={resendOTP} style={{color: (seconds > 0 || minutes > 0) ? 'gray' : 'orange', cursor: 'pointer', pointerEvents: (seconds > 0 || minutes > 0) ? 'none' : 'auto'}}>Resend OTP</p>
            </div>

            <button
              className={submitBtn ? "btn-submit" : "faded btn-submit"}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        ) : (
          <div className="mobile-container">
            {/* <h2>Enter your mobile number</h2> */}
            <div className="userInput">
              <input
                className="phone-input"
                type="tel"
                name="mobile"
                maxLength={10}
                onChange={handleMobileInput}
                value={mobile}
                placeholder="*Enter your Mobile Number"
                disabled
                readOnly
              />
              {mobileAlert.state && (
                <div className="mobile-alert-label primary">
                  {mobileAlert.text}
                </div>
              )}
            </div>
            <button
              className={!mobileAlert.state ? "btn-submit" : "faded btn-submit"}
              onClick={handleOtpSent}
            >
              Send OTP
            </button>
          </div>
        )}
      </div>

      <div
        className="footer-banner"
        style={{ bottom: "0px", position: "fixed", width: "100%" }}
      >
        <Footer />
      </div>
    </>
  );
}
