import axios from "axios";

export const CardVerify = async (cardNumber) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}api/user/card/verify`, {cardNumber}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}