import axios from "axios";
export const getCoupons = async (currentPage) => {
    try {

        console.log(JSON.parse(sessionStorage.getItem("apiResponse")));
        const { amount, mobile, almond_transaction_id } = JSON.parse(sessionStorage.getItem("apiResponse")).userData;
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}api/vouchers/fetch-voucher`, {
            amount,
            mobile,
            transaction_id:almond_transaction_id,
        });
        return response;
    } catch (err) {
        return err.message
    }
}