import axios from "axios";
export const activateCoupon = async (id, coupon_code) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}api/user/redeemCoupon/activation`, { id, coupon_code }, {
            headers: {
                "x-access-token": localStorage.getItem("token")
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}