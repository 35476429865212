import { useEffect, useState, useContext, useRef } from "react";
import { Alert } from "react-bootstrap";
import { LoaderContext } from "../context/LoaderContext";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../context/ThemeProvider";
import { loginWithOtp } from "../service/login";
import CryptoJS from "crypto-js";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

import { CardVerify } from "../service/cardVerify";
const Card = () => {
  const { setLoading } = useContext(LoaderContext);
  const [otp, setOtp] = useState();
  const [card, setcard] = useState(false);
  const [cardALert, setcardALert] = useState({ state: false });
  const [submitBtn, setSubmitBtn] = useState(true);
  const [alert, setAlert] = useState({ state: false, variant: "", text: "" });
  const { theme, setTheme } = useContext(ThemeContext);

  const [isPaymentInitiated, setPaymentInitiated] = useState(false);

  const [payHtml, setPayHTML] = useState("");

  const formRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // useEffect(() => {
    //   if (card.length === 16 && card > 5000000000) {
    //     setcardALert({ state: false });
    //   } else {
    //   //   setcardALert({ state: true, text: "Card number must be 16 digits." });
    //   }
    // }, [card]);

  const AesEncrypt = (value) => {
    const key = "H?E(H+MbNaThWmZqPt7w!z$C&Q)J@NcS";
    const iv = "ALM$#HQPO@#R@#F0";
    let cipher = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    let b64 = cipher.toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    let eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  };

  const formatCardNumber = (value) => {
    value = value.replace(/\D/g, ''); // Remove non-digit characters

    if (value.length > 0) {
      const formattedValue = value
        .replace(/(\d{4})/g, '$1-') // Add hyphens after every 4 digits
        .slice(0, 19); // Limit to 19 characters (including hyphens)
      return formattedValue;
    }

    return '';
  };


  const handleCardInput = (e) => {
    let cardNumber = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

    if (validateCard(cardNumber)) {
      const formattedCardNumber = formatCardNumber(cardNumber);
      e.target.value = formattedCardNumber;
      setcard(AesEncrypt(cardNumber)); // Store the encrypted card number in state
      setcardALert({ state: false, text: '' });
    } else {
      const input = e.target;
      input.value = input.value.replace(/[^0-9]/g, '');
    }
  };


  const validateCard = (value) => {
    console.log(value);
    // Remove any non-digit characters from the card number
    value = value.replace(/\D/g, "");

    if (/^\d{16}$/.test(value)) {
      // Check if the first two digits are between 50 and 55
      const firstTwoDigits = parseInt(value.substring(0, 2), 10);
      console.log(0);
      if (firstTwoDigits >= 50 && firstTwoDigits <= 55) {
        console.log(1);
        setcardALert({ state: false, text: "" });
        return true;
      } else {
        console.log(2);
        setcardALert({ state: true, text: "Please Enter a valid Card Number" });
        return false;
      }
    } else {
      setcardALert({ state: true, text: "Card number must be 16 digits." });
      return false;
    }
  };

  const handleSubmit = async () => {
    if (submitBtn) {
      setLoading(true);
      const response = await CardVerify(card);
      console.log(response);
      if (response.status === 200) {
        setLoading(false);

        setPayHTML(response.data);

        setTimeout(() => {
          handlePaymentInitiate();
        }, 1000)

        try {

          const parser = new DOMParser();
          const doc = parser.parseFromString(response.data, 'text/html');

          // Find and submit the form
          const form = doc.querySelector('.almond-paytm');
          form.submit(); // Programmatically submit the form
          console.log(form);

        } catch (err) {
          console.log("error while submitting the form", err);
        }
        document.querySelector("input[name='card']").value = "";

      } else {
        setLoading(false);
        setAlert({
          state: true,
          variant: "danger",
          text: "Card number is invalid ",
        });
        document.querySelector(".phone-input").value = "";
      }
    }
  };

  useEffect(() => {
    console.log(cardALert);
  }, [cardALert]);


  const handlePaymentInitiate = () => {
    const form = document.querySelector(".almond-paytm");
    if (form) {
      form.submit();
    } else {
      // Handle the case when the form is not found
      console.error("Form with class 'almond-paytm' not found.");
    }
  }


  return (
    <>
      {!isPaymentInitiated && <div>
        <Header />
        <div className="container">
          {alert.state && (
            <Alert variant={alert.variant || ""} dismissible>
              {alert.text || ""}
            </Alert>
          )}
          <img
            style={{ margin: "0 auto 40px" }}
            className="login-logo"
            src={theme ? "/images/pepsi.png" : "/images/pepsi.png"}
            alt=""
          />
          <div className="mobile-container">
            {/* <h2>Enter your Credit Card Number</h2> */}
            <div className="userInput">
              <input
                className="phone-input"
                type="tel"
                name="card"
                maxLength={16}
                onChange={handleCardInput}
                placeholder="Enter 16 digit Master Card Number"
              />
              {cardALert.state && (
                <div className="mobile-alert-label primary">{cardALert.text}</div>
              )}
            </div>
          </div>
          <div className="otp-container">
            <button
              className={!cardALert.state ? "btn-submit" : "faded btn-submit"}
              onClick={()=> { if(!cardALert.state) {handleSubmit()}}}
            >
              Submit
            </button>
          </div>
        </div>

        <div
          class="footer-banner"
          style={{ bottom: "0px", position: "fixed", width: "100%" }}
        >
          <Footer />
        </div>

        <div className="mc-payment-container">
          <div

            dangerouslySetInnerHTML={{
              __html: payHtml,
            }}
          />
        </div>


      </div>}
    </>
  );
};

export default Card;
