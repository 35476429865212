import { useContext, useEffect, useState } from "react";
import { RedeemCoupon } from "../../service/RedeemCoupon";
// import AlertModal from "../Models/AlertModel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LoaderContext } from "../../context/LoaderContext";
import { sendMessage } from "../../service/SendMessage";
import { getStatus } from "../../service/GetStatus";
import { activateCoupon } from "../../service/ActivateCoupon";

export default function ProductItem({
  coupon,
  index,
  handleCoupons,
  currPage,
}) {

  console.log({ coupon });
  const [show, setShow] = useState(false);
  const [errorModal, setErrorModal] = useState({ state: false });
  const [copied, setCopied] = useState(false);
  const [copiedTwo, setCopiedTwo] = useState(false);
  const [resultModal, setResultModal] = useState({ state: false });
  const [whatsappModal, setWhatsappModal] = useState({ state: false });
  const [statusModal, setStatusModal] = useState(false);
  const [activationModal, setActivationModal] = useState(false);
  const [activationCoupon, setActivationCoupon] = useState(null);

  const { setLoading } = useContext(LoaderContext);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setCopied(false);
    setCopiedTwo(false);
  }, [coupon]);

  const handleResultModalClose = () => {
    setResultModal({ state: false });
    setErrorModal({ state: false });
  };

  const handleRedeem = async (coupon) => {
    setLoading(true);
    const response = await RedeemCoupon(coupon.id, coupon.coupon_code);
    if (response?.data?.success) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      console.log(response);
      setResultModal({ state: true, card: response.data.data });
    } else if (response.status === 201) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setErrorModal({ state: true });
    } else {
      setLoading(false);
    }
    handleCoupons();
  };

  const copyText = (text, id) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    if (id === 1) {
      setCopied(true);
    } else {
      setCopiedTwo(true);
    }
  };

  const handleWhatsapp = async (id) => {
    setLoading(true);
    const response = await sendMessage(id);
    setLoading(false);
    if (response.data.success) {
      setWhatsappModal({ state: true });
    }
  };

  const handleStatusCheck = async (coupon) => {
    setLoading(true);
    const response = await getStatus(coupon?.id, coupon?.coupon_code);
    handleCoupons();
    setLoading(false);
    if (response && response.data.success) {
      setStatusModal({ status: true });
    } else {
      setStatusModal({ status: false });
      setTimeout(() => {
        handleCoupons();
      }, 1000);
    }
  };

  const handleActivate = async (coupon) => {
    setLoading(true);
    const response = await activateCoupon(coupon?.id, coupon?.coupon_code);
    if (response && response?.data?.data)
      setActivationCoupon(response?.data?.data);
    handleCoupons();
    setLoading(false);
    if (response && response.data.success) {
      setActivationModal({ status: true });
    } else {
      setActivationModal({ status: false });
    }
  };

  useEffect(() => {
    console.log(resultModal);
  }, [resultModal]);

  // const maxWords = 35;
  // let truncatedDescription = coupon?.description || "__";
  // if (truncatedDescription.split(' ').length > maxWords) {
  //   truncatedDescription = truncatedDescription.split(' ').slice(0, maxWords).join(' ') + '...';
  // }

  // const currentTime = new Date(); // Current time
  // const specificTime = new Date("2023-06-06T10:30:00"); // Specific time
  // const differenceInMilliseconds = specificTime - currentTime;
  // const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  // const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  // const differenceInHours = Math.floor(differenceInMinutes / 60);
  // const differenceInDays = Math.floor(differenceInHours / 24);

  return (
    <div className="mc-product-item">
      {/* <div className="mc-product-serial-number">{((currPage - 1) * 10) + index + 1}.</div> */}
      <div className="mc-product-image-container">
        <div class="card" style={{ width: '12rem', height: '250px', backgroundColor: 'white', border: "none", zIndex: '' }}>
          <img
            className="mc-product-image"
            src={coupon?.image || "images/coupon.png"}
            alt=""
          />
        </div>

        <div className="mc-product-desc-container mc-coupon-code-container" style={{ marginLeft: '-4px' }}>
          {/* <h3 className="mc-product-name">{coupon?.coupon_code || "__"}</h3> */}
          <div className="mc-price-container">
            {/* <span className="min-price">{coupon?.currency?.symbol || ""}{coupon?.cost || "₹100"}</span> */}
            <span>Voucher Value: </span>
            <span className="max-price">
              {coupon?.currency?.symbol || ""}
              {coupon?.price_point || ""}
            </span>
          </div>
        </div>
      </div>

      {coupon.status == 2 && <div className="mc-product-desc-container description">
        {/* {coupon?.status === 2 &&
          coupon?.cardNumber &&
          new Date(coupon.expireAt) > new Date() && (
            <>
              <p className="mc-coupon-label">Tracking ID</p>
              <h3 className="mc-coupon-card">
                <span className="mc-coupon-card-text">
                  {coupon?.cardNumber || "___"}
                </span>
                <span onClick={() => copyText(coupon?.cardNumber, 1)}>
                  {!copied ? (
                    <i className="fa-solid fa-copy"></i>
                  ) : (
                    <i className="fa-solid fa-check"></i>
                  )}
                </span>
              </h3>
            </>
          )} */}
        {coupon?.status === 2 &&
          coupon?.cardPin &&
          new Date(coupon.expireAt) > new Date() && (
            <>
              <p className="mc-coupon-label">Gift Card Code</p>
              <h3 className="mc-coupon-pin">
                <span className="mc-coupon-pin-text">{coupon?.cardPin}</span>
                <span onClick={() => copyText(coupon?.cardPin, 2)}>
                  {!copiedTwo ? (
                    <i className="fa-solid fa-copy"></i>
                  ) : (
                    <i className="fa-solid fa-check"></i>
                  )}
                </span>
              </h3>
            </>
          )}
        {coupon?.status === 2 && coupon?.activationUrl && (
          <>
            <p className="mc-coupon-label">Activation Url</p>
            <h3 className="mc-coupon-pin">
              <span className="mc-coupon-card-text">
                {coupon?.activationUrl || "__"}
              </span>
              <span
                onClick={() => window.open(coupon?.activationUrl, "_blank")}
              >
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </span>
            </h3>
          </>
        )}
        {coupon?.status === 2 && coupon?.activationCode && (
          <>
            <p className="mc-coupon-label">Activation Code</p>
            <h3 className="mc-coupon-pin">
              <span className="mc-coupon-pin-text">
                {coupon?.activationCode || "__"}
              </span>
              <span onClick={() => copyText(coupon?.activationCode, 2)}>
                {!copiedTwo ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-solid fa-check"></i>
                )}
              </span>
            </h3>
          </>
        )}
      </div>}

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">Title</p>
        <h3 className="mc-coupon-title">{coupon?.title || "__"}</h3>
        <p className="mc-coupon-label">Description</p>
      <h3 className="mc-coupon-description"> To add a Gift Card to your Amazon Pay balance, go to <a href="https://www.amazon.in/gp/css/gc/payment?isRequestFromSP=true" target="_blank" style={{textDecoration: 'none'}}>Add a Gift Card.</a> Then, enter your Gift Card Code and select Add Gift Card to Balance. To use your Amazon Pay Gift Card balance for a purchase, select Amazon Pay balance on the check-out page .</h3>
      <br />
      </div>

      <div className="mc-product-desc-container description">
        {/* <p className="mc-coupon-label">SKU</p>
        <h3 className="mc-coupon-title">{coupon?.sku || "__"}</h3> */}
        {/* <p className="mc-coupon-label">Brand Name</p>
        <h3 className="mc-coupon-description">{coupon?.brand_name || "__"}</h3> */}
        {/* {coupon.expireAt && (
          <>
            <p className="mc-coupon-label">Ordered On</p>
            <h3 className="mc-coupon-description">
              {new Date(coupon.created_at).toLocaleDateString() || "__"}
            </h3>
          </>
        )} */}
         {coupon?.status === 2 && coupon.expireAt && (
          <>
            <p className="mc-coupon-label">Activated On</p>
            <h3 className="mc-coupon-description">
              {new Date(coupon.redeem_date).toLocaleDateString() || "__"}
            </h3>
          </>
        )}
        {coupon?.status === 2 &&
          coupon.expireAt &&
          new Date(coupon.expireAt) > new Date() && (
            <>
              <p className="mc-coupon-label">Expires On</p>
              <h3 className="mc-coupon-description">
                {new Date(new Date(coupon.expireAt)).toLocaleDateString() ||
                  "__"}
              </h3>
            </>
          )}
        {new Date(coupon.expireAt) < new Date() && (
          <>
            <p className="mc-coupon-label">Expires On</p>
            <p className="mc-coupon-label" style={{ color: "#ea8423" }}>
              Coupon has expired
            </p>
          </>
        )}
        {coupon?.status === 2 && (
          <p className="mc-coupon-label">
            Terms & Conditions:{" "}
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href="https://almonds.ai/amazon-t-and-c/"
            >
              Click here
            </a>
          </p>
        )}

{coupon?.status === 2 &&
          coupon?.cardNumber &&
          new Date(coupon.expireAt) > new Date() && (
            <>
              <p className="mc-coupon-label">Tracking ID</p>
              <h3 className="mc-coupon-card">
                <span className="mc-coupon-card-text">
                  {coupon?.cardNumber || "___"}
                </span>
                {/* <span onClick={() => copyText(coupon?.cardNumber, 1)}>
                  {!copied ? (
                    <i className="fa-solid fa-copy"></i>
                  ) : (
                    <i className="fa-solid fa-check"></i>
                  )}
                </span> */}
              </h3>
            </>
          )}

          <p style={{fontWeight: 'bold', fontSize: '13px'}}>In case of any issue please contact - <u style={{textDecoration: 'none', color: '#3969f5'}}>Cs-reply@amazon.com</u></p>
      </div>

      {/* <div className="mc-coupon-container">
        {coupon && coupon?.status == 0 && new Date(coupon.expireAt) > new Date() && (
          <button
            className=" mc-coupon-redeem-button"
            onClick={() => {
              setShow(true);
            }}
          >
            Activate Now
          </button>
        )}
        {coupon &&
          coupon?.status === 2 &&
          new Date(coupon.expireAt) > new Date() && (
            <div>
              <button className="mc-coupon-redeem-button redeemed">
                Activated
              </button>
              <br />
              <button
                className="mc-coupon-redeem-button whatsapp-button"
                onClick={() => {
                  handleWhatsapp(coupon?.id);
                }}
              >
                <i className="fa-brands fa-whatsapp"></i>
              </button>
            </div>
          )}

        {coupon && coupon.status == 5 && (
          <div className="mc_card_status">
            <h5>Your payment is not completed!</h5>

          </div>

        )}
        {coupon && coupon.status == 6 && (
          <div className="mc_card_status">
            <h5>Your Coupon will be activated in 2 to 3 business days.</h5>

          </div>

        )}

        {coupon && coupon?.status == 1 && (
          <div>
            <button
              className="mc-btn-status"
              onClick={() => {
                handleStatusCheck(coupon);
              }}
            >
              Check Status
            </button>
          </div>
        )}
        {coupon && coupon?.status === 3 && !coupon?.cardNumber && (
          <div>
            <button
              className="mc-btn-status"
              onClick={() => {
                handleActivate(coupon);
              }}
            >
              Activate
            </button>
          </div>
        )}
      </div> */}
      {show && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "22px" }}>
              Please Confirm to activate this coupon!
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer>
            <Button
              style={{ padding: "8px 18px" }}
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn btn-outline-warning"
              // className="confirm-button"
              // variant="danger"
              onClick={() => {
                handleRedeem(coupon);
                handleClose();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {resultModal.state && (
        <Modal
          show={resultModal.state}
          onHide={handleResultModalClose}
          centered
        >
          <img className="mc-check-gif" src="images/check.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">
              Congratulations! Your Coupon is now Activated
            </h3>
            <p>Please find the details below.</p>
            <div className="mc-product-desc-container description">
              {resultModal?.card[0]?.cardNumber && (
                <p className="mc-coupon-label">Voucher Code</p>
              )}
              <h3 className="mc-coupon-card">
                <span className="mc-coupon-card-text">
                  {resultModal?.card[0]?.cardNumber || "Null"}
                </span>
                <span
                  onClick={() => copyText(resultModal?.card[0]?.cardNumber, 1)}
                >
                  {!copied ? (
                    <i className="fa-solid fa-copy"></i>
                  ) : (
                    <i className="fa-solid fa-check"></i>
                  )}
                </span>
              </h3>
              {resultModal?.card[0]?.cardPin && (
                <p className="mc-coupon-label">Voucher Pin</p>
              )}
              <h3 className="mc-coupon-pin">
                <span className="mc-coupon-pin-text">
                  {resultModal?.card[0]?.cardPin || "Null"}
                </span>
                <span
                  onClick={() => copyText(resultModal?.card[0]?.cardPin, 2)}
                >
                  {!copiedTwo ? (
                    <i className="fa-solid fa-copy"></i>
                  ) : (
                    <i className="fa-solid fa-check"></i>
                  )}
                </span>
              </h3>
              {resultModal?.card[0]?.activationUrl && (
                <>
                  {resultModal?.card[0]?.activationUrl && (
                    <p className="mc-coupon-label">Activation Url</p>
                  )}
                  <h3 className="mc-coupon-card">
                    <span className="mc-coupon-card-text">
                      {resultModal.card.activationUrl || "Null"}
                    </span>
                    <span
                      onClick={() =>
                        window.open(
                          resultModal?.card[0]?.activationUrl,
                          "_blank"
                        )
                      }
                    >
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                  </h3>
                </>
              )}
              {resultModal?.card[0]?.activationCode && (
                <>
                  {resultModal?.card[0]?.activationCode && (
                    <p className="mc-coupon-label">Activation Code</p>
                  )}
                  <h3 className="mc-coupon-card">
                    <span className="mc-coupon-card-text">
                      {resultModal?.card[0]?.activationCode || "Null"}
                    </span>
                    <span
                      onClick={() =>
                        copyText(resultModal?.card[0]?.activationCode, 1)
                      }
                    >
                      {!copied ? (
                        <i className="fa-solid fa-copy"></i>
                      ) : (
                        <i className="fa-solid fa-check"></i>
                      )}
                    </span>
                  </h3>
                </>
              )}
            </div>
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="success"
              onClick={handleResultModalClose}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Error modal */}
      {errorModal.state && (
        <Modal show={errorModal.state} onHide={handleResultModalClose} centered>
          <img className="mc-check-gif" src="images/cross.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">Oh! Sorry !</h3>
            <p>Something went wrong, please try again after sometimes.</p>
            <div className="mc-product-desc-container description"></div>
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="danger"
              onClick={handleResultModalClose}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {whatsappModal.state && (
        <Modal
          show={whatsappModal.state}
          onHide={handleResultModalClose}
          centered
        >
          <img className="mc-check-gif" src="images/check.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">Message Sent !</h3>
            {/* <p>Please find your coupon creds.</p> */}
            {/* <div className="mc-product-desc-container description">
                    <h3 className="mc-coupon-card"><span className="mc-coupon-card-text">{coupon?.cardNumber || "Null"}</span><span onClick={() => copyText(coupon?.cardNumber, 1)}>{!copied ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                    <h3 className="mc-coupon-pin"><span className="mc-coupon-pin-text">{coupon?.cardPin || "Null"}</span><span onClick={() => copyText(coupon?.cardPin, 2)}>{!copiedTwo ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                </div> */}
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="success"
              onClick={() => {
                setWhatsappModal({ state: false });
              }}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal centered show={statusModal}>
        <Modal.Body>
          {statusModal.status && (
            <div>
              <img
                className="mc-pending-image"
                src="/images/pending.jpg"
                alt=""
              />
              <p className="mc-coupon-status-text">Your coupon is ready !</p>
            </div>
          )}
          {!statusModal.status && (
            <div>
              <img
                className="mc-pending-image"
                src="/images/error.jpg"
                alt=""
              />
              <p className="mc-coupon-status-text">
                Error while checking status.
                <br />
                We will send you a notification when it is ready.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          onClick={() => {
            setStatusModal(false);
          }}
        >
          <button
            className="mc-btn-status"
            style={{ margin: "10px 10px 10px auto" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={activationModal}>
        {activationModal?.status && (
          <Modal.Body>
            <div className="mc-container-activation">
              <img className="mc-check-gif" src="images/check.gif" alt="" />
              <div className="mc-redeemed-coupon-details">
                <h3 className="mc-coupon-redeemed-text">
                  Congratulations! Your Coupon is now Activated
                </h3>
                <p>Please find the details below.</p>
                <div className="mc-product-desc-container description">
                  {activationCoupon &&
                    activationCoupon?.cards[0]?.cardNumber && (
                      <p className="mc-coupon-label">Card Number</p>
                    )}
                  <h3 className="mc-coupon-card">
                    <span className="mc-coupon-card-text">
                      {activationCoupon?.cards[0].cardNumber || "Null"}
                    </span>
                    <span onClick={() => copyText(coupon?.cardNumber, 1)}>
                      {!copied ? (
                        <i className="fa-solid fa-copy"></i>
                      ) : (
                        <i className="fa-solid fa-check"></i>
                      )}
                    </span>
                  </h3>
                  {activationCoupon && activationCoupon?.cards[0]?.cardPin && (
                    <p className="mc-coupon-label">Card Pin</p>
                  )}
                  <h3 className="mc-coupon-pin">
                    <span className="mc-coupon-pin-text">
                      {activationCoupon?.cards[0]?.cardPin || "Null"}
                    </span>
                    <span onClick={() => copyText(coupon?.cardPin, 2)}>
                      {!copiedTwo ? (
                        <i className="fa-solid fa-copy"></i>
                      ) : (
                        <i className="fa-solid fa-check"></i>
                      )}
                    </span>
                  </h3>
                  {activationCoupon &&
                    activationCoupon?.cards[0]?.activationUrl && (
                      <>
                        {activationCoupon?.cards[0]?.activationUrl && (
                          <p className="mc-coupon-label">Activation Url</p>
                        )}
                        <h3 className="mc-coupon-card">
                          <span className="mc-coupon-card-text">
                            {activationCoupon?.cards[0].activationUrl || "Null"}
                          </span>
                          <span
                            onClick={() =>
                              window.open(
                                activationCoupon?.cards[0]?.activationUrl,
                                "_blank"
                              )
                            }
                          >
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                          </span>
                        </h3>
                      </>
                    )}
                  {activationCoupon?.cards[0]?.activationCode && (
                    <>
                      {activationCoupon?.cards[0]?.activationCode && (
                        <p className="mc-coupon-label">Activation Code</p>
                      )}
                      <h3 className="mc-coupon-card">
                        <span className="mc-coupon-card-text">
                          {activationCoupon?.cards[0].activationCode || "Null"}
                        </span>
                        <span
                          onClick={() =>
                            copyText(
                              activationCoupon?.cards[0].activationCode,
                              1
                            )
                          }
                        >
                          {!copied ? (
                            <i className="fa-solid fa-copy"></i>
                          ) : (
                            <i className="fa-solid fa-check"></i>
                          )}
                        </span>
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer
          onClick={() => {
            setActivationModal(false);
          }}
        >
          <button
            className="mc-btn-status"
            style={{ margin: "10px 10px 10px auto" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
