import React from "react";
import { useContext} from 'react';
import { ThemeContext } from "../context/ThemeProvider";

const Footer = () => {

  const { theme } = useContext(ThemeContext);

  return (
    <div>
      <footer class="footer text-center">
        <div
          className="text-center p-3 d-md-flex justify-content-between"
        >
          <div className="ps-sm-3 mb-md-0 mb-2" style={{color: '#004C97'}}>
            Copyright © 2024:
            <a
              className="text"
              style={{ textDecoration: "none", color: '#004C97' }}
              // href="https://almonds.ai/"
            >
              {" "}
              <u style={{fontWeight: 'bold', textDecoration: 'none'}}> PepsiCo</u> All rights reserved.
            </a>
          </div>
          <div className="pe-sm-3" >
            Made with &#9829; by{" "}
            <img
              src={theme ? "/images/almond-logo-dark.png" : "/images/almond-logo.png"}
              alt=""
              width={100}
              style={{ marginLeft: "-10px" }}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
